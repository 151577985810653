import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AccountPanel from "../../components/AccountPanel";
import * as CognitoService from "../../services/cognito.service";

export default function PersonalInfo() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [changesDetected, setChangesDetected] = useState(false);

  useEffect(() => {
    CognitoService.getUserAttributes()
      .then((result) => {
        setUserInfo(result);
        setFirstName(result.given_name);
        setLastName(result.family_name);
        setBirthdate(result.birthdate);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (firstName && lastName && birthdate)
      if (
        firstName !== userInfo.given_name ||
        lastName !== userInfo.family_name ||
        birthdate !== userInfo.birthdate
      ) {
        setChangesDetected(true);
      } else setChangesDetected(false);
  }, [firstName, lastName, birthdate]);

  const handleChange = (e, setState) => {
    setState(e.target.value);
  };

  const resetFields = () => {
    setFirstName(userInfo.given_name);
    setLastName(userInfo.family_name);
    setBirthdate(userInfo.birthdate);
    setChangesDetected(false);
  };

  const updateAttributes = () => {
    const attributes = {
      given_name: firstName,
      family_name: lastName,
      birthdate: birthdate,
    };

    CognitoService.updateUserAttibutes(attributes)
      .then((result) => {
        if (result === "SUCCESS") {
          setChangesDetected(false);
          window.location.reload(false);
        }
      })
      .catch((error) => {});
  };

  return (
    <div>
      <AccountPanel />

      <div className="side-content">
        <h2>Personal Information</h2>

        <Box sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                size="small"
                name="firstName"
                variant="outlined"
                fullWidth
                id="firstName"
                label="First Name"
                value={firstName || ""}
                onChange={(e) => handleChange(e, setFirstName)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                name="lastName"
                variant="outlined"
                id="lastName"
                label="Last Name"
                value={lastName || ""}
                fullWidth
                onChange={(e) => handleChange(e, setLastName)}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 4 }}>
            <TextField
              size="small"
              variant="outlined"
              name="birthdate"
              id="birthdate"
              label="Birthdate"
              type="date"
              value={birthdate || ""}
              onChange={(e) => handleChange(e, setBirthdate)}
              fullWidth
            />
          </Box>

          {changesDetected && (
            <Box sx={{ mt: 4 }}>
              <Grid container justify="flex-end" spacing={2}>
                <Grid item>
                  <span
                    className="cancel neutral"
                    role="button"
                    onClick={() => resetFields()}
                  >
                    Cancel
                  </span>
                </Grid>
                <Grid item>
                  <span
                    className="update success"
                    role="button"
                    onClick={() => updateAttributes()}
                  >
                    Update
                  </span>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
}
